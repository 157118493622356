import Vue from "vue";
import Vuex from "vuex";
import store2 from "store2";
import router from "@/router/index";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {}, // 用户信息
    token: null,
  },
  getters: {},
  mutations: {
    setStateFun(plyload, obj) {
      plyload[obj.key] = obj.value;
    },
    LOGOUT(plyload, obj) {
      store2.remove("token");
      store2.remove("userInfo");
      plyload.userInfo = {};
      plyload.token = null;
      router.replace("/login");
    },
  },
  actions: {},
  modules: {},
});
